import React from "react"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import Testimonials from "../components/Testimonials"
import { graphql, useStaticQuery } from "gatsby"

const getImage = graphql`
  {
    fluid: file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default () => {
  const data = useStaticQuery(getImage)

  return (
    <Layout>
      <Banner
        title="Relief. Remedy. Recovery"
        ishome={true}
        image={data.fluid.childImageSharp.fluid}
      />
      <main>
        <div className="main-content">
          <div className="c-home-feature">
            <div className="container">
              <div className="row">
                <div className="c-home-feature__section">
                  <div className="c-home-feature__header c-shape" data-scroll>
                    <svg
                      width="78"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path"
                        d="M76.813 7.029a8.148 8.148 0 0 0-.674-1.5C74.365 2.46 70.884.632 67.362.218 63.838-.196 60.275.65 56.966 1.93l.39.974a17.657 17.657 0 0 1 10.592-1.48c2.309.4 4.6 1.314 6.23 2.999 1.63 1.685 2.478 4.234 1.739 6.459-.476 1.433-1.548 2.609-2.79 3.473-2.061 1.438-4.556 2.273-6.974 2.883-.051.013-.103.024-.153.038-8.971 1.564-17.791-1.842-17.871-1.873L22.247 4.729c-2.014-.93-4.194-1.767-6.433-2.304a15.988 15.988 0 0 1 2.182-.708c6.8-1.667 12.369.649 18.483 3.294l11.898 5.148c5.52 2.388 12.214 3.39 18.17 2.285.312-.058 3.585-.98 3.71-1.23.5-.236.898-.68 1.04-1.257a1.902 1.902 0 1 0-3.693-.913c-.184.742.1 1.486.658 1.935-5.193 1.956-11.27.755-16.188-1.397-3.732-1.633-7.56-3.103-11.24-4.85-7.542-3.583-18.93-7.072-26.863-2.676-2.215-.348-4.46-.355-6.636.178-4.008.982-7.742 4.458-7.3 8.894.275 2.742 2.235 5.246 4.832 6.17l.4-.86c-1.988-1.1-3.923-2.75-4.288-4.993-.254-1.554.305-3.145 1.156-4.47 2.365-3.678 5.787-3.74 9.292-2.976-1.045 1.092-1.838 2.408-2.191 3.873-.831 3.445 1.022 7.284 4.129 8.989 2.885 1.583 6.59 1.318 9.39-.404a1.898 1.898 0 0 0-.152-3.645 1.902 1.902 0 0 0-2.304 1.39 1.897 1.897 0 0 0 .786 2.028c-2.413 1.012-5.275.974-7.513-.372-2.828-1.701-4.31-5.48-3.133-8.562.456-1.196 1.238-2.186 2.203-3.003.73.19 1.46.4 2.179.609 6.709 1.949 13.224 4.595 19.654 7.306 4.173 2.137 8.652 3.825 13.146 5.138 3.693 1.079 7.516 1.905 11.372 2.035 3.215.107 6.475-.44 9.504-1.513 2.782-.985 6.27-2.601 7.873-5.222 1.015-1.66 1.064-3.753.443-5.617"
                        fill="#35C4C0"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="c-home-feature__content">
                    <h2 className="heading-large">Why Clients Choose Us</h2>
                    <p>
                      We offer a wide range of treatments, each of which is
                      tailored to the needs of our clients. In many cases, more
                      than one modality can be utilised to deliver the best
                      results in a single session.
                    </p>
                    <a href="/about" className="c-btn c-btn--primary">
                      Read More
                    </a>
                  </div>
                  <div className="c-home-feature__media">
                    <img src={"home-1.jpg"} alt="Step 2 Wellbeing" />
                  </div>
                </div>

                <div className="c-home-feature__section">
                  <div className="c-home-feature__header c-shape" data-scroll>
                    <svg
                      width="78"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path"
                        d="M76.813 7.029a8.148 8.148 0 0 0-.674-1.5C74.365 2.46 70.884.632 67.362.218 63.838-.196 60.275.65 56.966 1.93l.39.974a17.657 17.657 0 0 1 10.592-1.48c2.309.4 4.6 1.314 6.23 2.999 1.63 1.685 2.478 4.234 1.739 6.459-.476 1.433-1.548 2.609-2.79 3.473-2.061 1.438-4.556 2.273-6.974 2.883-.051.013-.103.024-.153.038-8.971 1.564-17.791-1.842-17.871-1.873L22.247 4.729c-2.014-.93-4.194-1.767-6.433-2.304a15.988 15.988 0 0 1 2.182-.708c6.8-1.667 12.369.649 18.483 3.294l11.898 5.148c5.52 2.388 12.214 3.39 18.17 2.285.312-.058 3.585-.98 3.71-1.23.5-.236.898-.68 1.04-1.257a1.902 1.902 0 1 0-3.693-.913c-.184.742.1 1.486.658 1.935-5.193 1.956-11.27.755-16.188-1.397-3.732-1.633-7.56-3.103-11.24-4.85-7.542-3.583-18.93-7.072-26.863-2.676-2.215-.348-4.46-.355-6.636.178-4.008.982-7.742 4.458-7.3 8.894.275 2.742 2.235 5.246 4.832 6.17l.4-.86c-1.988-1.1-3.923-2.75-4.288-4.993-.254-1.554.305-3.145 1.156-4.47 2.365-3.678 5.787-3.74 9.292-2.976-1.045 1.092-1.838 2.408-2.191 3.873-.831 3.445 1.022 7.284 4.129 8.989 2.885 1.583 6.59 1.318 9.39-.404a1.898 1.898 0 0 0-.152-3.645 1.902 1.902 0 0 0-2.304 1.39 1.897 1.897 0 0 0 .786 2.028c-2.413 1.012-5.275.974-7.513-.372-2.828-1.701-4.31-5.48-3.133-8.562.456-1.196 1.238-2.186 2.203-3.003.73.19 1.46.4 2.179.609 6.709 1.949 13.224 4.595 19.654 7.306 4.173 2.137 8.652 3.825 13.146 5.138 3.693 1.079 7.516 1.905 11.372 2.035 3.215.107 6.475-.44 9.504-1.513 2.782-.985 6.27-2.601 7.873-5.222 1.015-1.66 1.064-3.753.443-5.617"
                        fill="#35C4C0"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <div className="c-home-feature__content">
                    <h2 className="heading-large">
                      What treatments do we offer?
                    </h2>
                    <p>
                      Our treatments range from sports and deep tissue massage
                      soothing strained, injured and tight muscles, through to
                      acupuncture as well as more gentler modalities such as
                      Swedish and pregnancy massage. There is something for
                      everyone.
                    </p>
                    <a href="/treatments" className="c-btn c-btn--primary">
                      Read More
                    </a>
                  </div>
                  <div className="c-home-feature__media">
                    <img src={"home-2.jpg"} alt="Step 2 Wellbeing" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Testimonials />
    </Layout>
  )
}
